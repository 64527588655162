document.addEventListener('livewire:init', function () {
    setTimeout(() => {
        createStickyItem();
    }, 1000);

    Livewire.on('TotalsUpdated', () => {
        setTimeout(() => {
            createStickyItem();
        }, 100);
    })
    Livewire.on('RefreshCategories', () => {
        createStickyItem();
    })

    function createStickyItem(first) {
        $('#fixed-totals-clone').remove();
        const clone = $('.fixed-totals').clone().attr('id', 'fixed-totals-clone');
        if (clone.length > 0) {
            $('body').append(clone[0].outerHTML);
            checkTotalsVisibility();
        }
    }

    $(window).scroll(function () {
        createStickyItem();
    });

    if (document.querySelector(".content > div") != null) {
        document.querySelector(".content > div").addEventListener("scroll", function () {
            createStickyItem();
        });
    }

    function checkTotalsVisibility() {
        if (isInViewport($('.fixed-totals-row'))) {
            $('#fixed-totals-clone').hide();
        } else {
            $('#fixed-totals-clone').show();
        }
    }

    function isInViewport(element) {
        const elementTop = $(element).offset().top;
        const elementBottom = elementTop + $(element).outerHeight();

        const viewportTop = $(window).scrollTop();
        const viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < (viewportBottom + 100);
    }
});