document.addEventListener('alpine:init', () => {
    Alpine.data('timer', (start = null) => ({
        start: start,
        current: null,
        intervalMethod: null,
        interval: null,
        getTime(start) {
            if(start != null){
                this.start = start;
            }
            clearInterval(this.intervalMethod);
            this.startTimer();
            this.intervalMethod = setInterval(() => {
                this.startTimer();
            }, 1000)
        },
        startTimer() {
            this.current = Date.now();
            if (this.start == null) {
                this.interval = null;
            } else {
                this.interval = this.msToTime((this.current - this.start));
            }
        },
        msToTime(duration) {
            let seconds = Math.floor((duration / 1000) % 60),
                minutes = Math.floor((duration / (1000 * 60)) % 60),
                hours = Math.floor((duration / (1000 * 60 * 60)));

            hours = (hours < 10) ? '0' + hours : hours;
            minutes = (minutes < 10) ? '0' + minutes : minutes;
            seconds = (seconds < 10) ? '0' + seconds : seconds;

            return hours + ':' + minutes + ':' + seconds;
        }
    }));

    Alpine.data('sortableCards', (route) => ({
        route: route,
        init(id) {
            if (id != undefined) {
                Sortable.create(document.getElementById('bordRow' + id), {
                    group: 'nested',
                    scroll: true,
                    onEnd: function (evt) {
                        var data = {
                            id: $(evt.item).data('id'),
                            position: evt.newIndex,
                            row_id: $(evt.to).closest('.row-item').data('id'),
                            _token: $('meta[name="csrf-token"]').attr('content')
                        }

                        $.ajaxSetup({
                            headers: {
                                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                            }
                        });
                        $.ajax({
                            type: "POST",
                            url: route,
                            data: data,
                            success: function (response) {
                                if (response.trigger_toggl_start) {
                                    Livewire.dispatch('TriggerStartToggl', {id: data.id});
                                } else if (response.trigger_toggl_stop) {
                                    Livewire.dispatch('TriggerStopToggl', {id: data.id});
                                }
                            }
                        });
                    }
                })
            }
        }
    }));

    Alpine.data('downtimeChart', (values, type, color, ref, event) => ({
        values: [],
        type: type,
        color: color,
        ref: ref,
        init() {
            this.values = JSON.parse(values)
            const chart = new Chart(this.$refs[this.ref].getContext('2d'), {
                type: this.type,
                data: {
                    labels: this.values.map(row => row.label),
                    datasets: [
                        {
                            fill: true,
                            backgroundColor: this.type !== 'bar' ? '#1c9cc475' : this.values.map(row => row.color),
                            borderColor: '#1eb9d7',
                            data: this.values.map(row => row.count)
                        }
                    ]
                },
                options: {
                    animation: false,
                    plugins: {
                        legend: {display: false},
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    return context.raw.toString() + '%';
                                },
                                title: function (context) {
                                    if (context && context[0] && context[0].label) {
                                        return context[0].label;
                                    }
                                    return '';
                                },
                                footer: (tooltipItems) => {
                                    let dataItem = this.values.find(row => row.label === tooltipItems[0].label);

                                    if(dataItem) {
                                        let html = '';
                                        html += dataItem.websites_down + " websites \r\n";
                                        html += dataItem.minutes + " minuten";
                                        return html;
                                    }

                                    return;
                                }
                            },
                            titleFont: {
                                size: 24
                            },
                            bodyFont: {
                                size: 24
                            },
                            footerFont: {
                                size: 24
                            }
                        }
                    },
                    scales: {
                        y: {
                            display: this.type !== 'bar',
                            min: 0,
                            max: this.type === 'line' ? (Math.round(Math.max.apply(Math, this.values.map(row => row.count)) + (Math.max.apply(Math, this.values.map(row => row.count)) <= 10 ? 1 : 5))) : 100,
                            ticks: {
                                color: this.color,
                                font: {
                                    size: 16
                                }
                            }
                        },
                        x: {
                            display: this.type !== 'bar',
                            ticks: {
                                color: this.color,
                                font: {
                                    size: 16
                                }
                            }
                        }
                    },

                }
            });

            Livewire.on(event, function(data) {
                this.values = data && data[0] ? data[0] : [];
                chart.data.labels = this.values.map(row => row.label)
                chart.data.datasets[0].data = this.values.map(row => row.count)
                chart.data.datasets[0].backgroundColor = (type !== 'bar' ? '#1c9cc475' : this.values.map(row => row.color))
                chart.update()
            });
        }
    }));

    Alpine.magic('confirmDialog', () => {
        return subject => Swal.fire({
            title: subject.title,
            text: subject.text,
            icon: subject.icon ?? 'question',
            customClass: {
                cancelButton: 'cancel-btn-swal',
                confirmButton: 'confirm-btn-swal'
            },
            didOpen: () => {
                document.querySelector('.confirm-btn-swal').addEventListener('click', (e) => {
                    e.stopPropagation();
                });
                document.querySelector('.cancel-btn-swal').addEventListener('click', (e) => {
                    e.stopPropagation();
                });
                document.querySelector('.swal2-container').addEventListener('click', function(e) {
                    if (!$(e.target).closest('.swal2-popup').length) {
                        e.stopPropagation();
                    }
                });
                document.querySelector('.swal2-popup').addEventListener('click', function(e) {
                    e.stopPropagation();
                });
            },
            showCancelButton: subject.showCancelButton ?? 1,
            cancelButtonColor: subject.cancelButtonColor ?? '#ef4444',
            confirmButtonColor: subject.confirmButtonColor ?? '#10b981',
            confirmButtonText: subject.confirmButtonText ?? 'Ja',
            cancelButtonText: subject.cancelButtonText ?? 'Annuleren'
        });
    });

    Alpine.magic('notification', () => {
        return subject => $wireui.notify({
            title: subject.title,
            description: subject.text,
            icon: subject.icon ?? 'success'
        });
    });

    Alpine.directive('clipboard', (el,  { value, modifiers, expression }) => {
        if (!el.hasAttribute('data-clipboard-initialized')) {
            let text = el.innerHTML;
            let content = expression;
            el.style.cssText += 'cursor:copy';
            el.addEventListener('click', () => {
                navigator.clipboard.writeText(content);
                el.setAttribute("disabled", "");
                if(modifiers.includes('check-only')) {
                    el.innerHTML = '<i class="fa fa-check" style="margin-right: 5px;"></i>';
                } else {
                    el.innerHTML = '<i class="fa fa-check" style="margin-right: 5px;"></i> Gekopieerd';
                }

                setTimeout(function () {
                    el.innerHTML = text;
                    el.style.cssText += 'cursor:copy';
                    el.removeAttribute('disabled');
                }, 2000);
                window.$wireui.notify({
                    title: 'Gekopieerd!',
                    description: 'De data is gekopieerd naar je klembord!',
                    icon: 'success'
                })
            });

            el.setAttribute('data-clipboard-initialized', 'true');
        }
    });
    Alpine.magic('clipboard', () => {
        return subject => navigator.clipboard.writeText(subject)
    })

    Alpine.magic('tooltip', el => message => {
        let instance = tippy(el, { content: message, trigger: 'manual' })

        instance.show()

        setTimeout(() => {
            instance.hide()

            setTimeout(() => instance.destroy(), 150)
        }, 2000)
    })

    // Directive: x-tooltip
    Alpine.directive('tooltip', (el, { expression }) => {
        tippy(el, { content: expression })
    })

    Alpine.directive('linkify', (el, { expression }, { evaluateLater, effect }) => {
        const evaluate = evaluateLater(expression);
        effect(() => evaluate(html =>
            el.innerHTML = html.split(' ').map(word => word.startsWith('http') && URL.canParse(word) ? `<a href="${word}" target="_blank" style="color: deepskyblue;">${word}</a>` : word).join(' ')
        ));
    })
})