let slider = null;
let isDown = false;
let startX;
let scrollLeft;
let scrollPos = 0;

document.addEventListener('livewire:init', function () {
    initializeSlider();
});

document.addEventListener('livewire:navigated', function () {
    initializeSlider();
});

function initializeSlider() {
    slider = document.querySelector('.bord-rows');
    isDown = false;
    startX;
    scrollLeft;
    scrollPos = 0;

    if (slider !== undefined && slider != null) {
        slider.addEventListener('mousedown', (e) => {
            if (e.which && e.which !== 1) {
                return;
            }

            if ($(e.target).closest('.row-item').length || $(e.target).hasClass('row-item')) {
                isDown = false;
                return;
            }
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            const itemContainers = $('.bord-row-item-container');

            for(const element of itemContainers) {
                element.setAttribute('draggable', false);
            }
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
            const itemContainers = $('.bord-row-item-container');

            for(const element of itemContainers) {
                element.setAttribute('draggable', true);
            }
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
            const itemContainers = $('.bord-row-item-container');

            for(const element of itemContainers) {
                element.setAttribute('draggable', true);
            }
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            // if ($(e.target).closest('.row-item').length || $(e.target).hasClass('row-item')) {
            //     return;
            // }
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 2; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }
}